import ExportApi from '@api/Documents/ExportApi'
import documentsApi from "@api/DocumentsApi";
import DocumentsApi from '@api/DocumentsApi'
import { AvvNodeGrammar, HtmlParser } from '@avvoka/editor'
import { useDialog, useSimpleDialog } from '@component-utils/dialogs'
import { useLocalize } from '@component-utils/localization'
import type { V } from '@component-utils/types'
import { equal, Key } from "@avvoka/shared";
import { computed, ref, watch } from 'vue'
import useDebounce from "~/features/_abstract/utils/debounce";
import DownloadDialog from "~/features/download_dialog/DownloadDialog.vue"
import {_parseWhitelist, _isDisallowedCharacter} from "~/features/editor/character-validation";
import {CompareUtils} from "~/javascripts/editors/compare";
import {useErrorToast, useToast} from "~/library/utils/toasts";
import { documentValidationDialog } from '~/views/templates/validations'
import { getCurrentQuestionnaireData } from '../QuestionnaireHelpers'
import { handleCharacterAutoreplacement } from '../utils'
import { uniqueArray } from '@avvoka/shared'
import axios, { AxiosError } from 'axios';
import { getCurrentDocumentId, useDocumentStore } from '~/stores/generic/document.store';
import { getActivePinia } from 'pinia';
import { hasEntryForAttribute } from '../question_helpers';
import createSummaryDialog from '~/views/templates/components/Summary/CreateSummaryDialog.vue';
import SummaryDocumentsApi from '~/api/ContractSummaries/SummaryDocumentsApi';

export const onTopbarMounted = async () => {
  const { valid_conditions } = await getCurrentQuestionnaireData()
  AvvStore.commit('SET_RESOLVED_CONDITIONS', valid_conditions)
}

export const goTo = (url: string, event: Event) => {
  if (event && (event.target as HTMLElement).classList.contains('disabled')) return
  window.location.href = url
}

const isResolvedQuestion = (
  question: Backend.Questionnaire.IQuestion,
  succession: Backend.Questionnaire.Succession = 0
) => {
  // todo need better handling here
  if (question.cond) {
    const resolvedCondition = AvvStore.state.resolvedConditions[question.cond]
    return Array.isArray(resolvedCondition) ? resolvedCondition.some((v) => equal(v, succession)) : resolvedCondition
  } else {
    return true
  }
}

const isRequiredUnanswered = (question: Backend.Questionnaire.IQuestion) =>
  question.opts?.required &&
  !hasEntryForAttribute(AvvStore.state.entries, question.att) &&
  !question.opts?.default &&
  isResolvedQuestion(question)
const bHasNonAnsweredQs = computed(() =>
  AvvStore.getters.nonDeletedQuestions
    .filter((q) => q.party === AvvStore.state.active_participant?.party_type)
    .some(isRequiredUnanswered)
)

export const init_questionnaire_button = () => {
  const path = 'document.topbar.tooltips.questionnaire'
  const hasRights = AvvStore.state.active_participant?.edit !== 'No'
  const currentParty = AvvStore.state.active_participant?.party_type
  const isController = AvvStore.state.isCurrentParticipantController
  const isLockedToCurrentParty = computed(
    () => AvvStore.state.locking_party === currentParty
  )
  const onePartyOnly = AvvStore.getters.parties.length === 1

  const bShowQuestionnaireButton = computed(() => {
    const hasQuestionsForCurrentParty =
      !!AvvStore.getters.nonDeletedQuestions.filter(
        (q) => q.party === AvvStore.state.active_participant?.party_type
      ).length
    const invalidStates = [
      'pending_external_signature',
      'partially_signed',
      'signed',
      'completed',
      'deleted'
    ]
    const isInValidState = !invalidStates.includes(AvvStore.state.doc_state)
    return hasQuestionsForCurrentParty && isInValidState
  })

  const bQuestionnaireButtonDisabled = computed(() =>
    AvvStore.state.is_doc_negotiable
      ? !hasRights || (!isLockedToCurrentParty.value && !onePartyOnly)
      : !hasRights
  )

  const questionnaireButtonTooltip = computed(() => {
    if (!hasRights) return localizeText(`${path}.no_perm`)
    if (AvvStore.state.doc_state === 'unlocked')
      return isController
        ? localizeText(`${path}.unlock.controller`)
        : localizeText(`${path}.unlock.no_controller`)
    if (!isLockedToCurrentParty.value && !onePartyOnly)
      return localizeText(`${path}.unlock.cp_edit`)
    else return localizeText(`${path}.go`)
  })

  const questionnaireLink = AvvStore.state.toolbarData.questionnaireLink

  return {
    bShowQuestionnaireButton,
    questionnaireLink,
    questionnaireButtonTooltip,
    bQuestionnaireButtonDisabled
  }
}

export const init_import_docx_button = () => {
  const bEditorIsEmpty = ref(false)

  void (async () => {
    await EditorFactory.onCreate('draft')
    const editor = EditorFactory.get('draft').get()
    await new Promise<void>((resolve) => {
      editor.onReady.subscribe((data, event) => {
        if (data.ready) event.unsub() && resolve()
      })
    })

    const resolve = () => {
      bEditorIsEmpty.value = editor.isEmpty()
    }

    editor.onChange.subscribe(resolve)
    resolve()
  })()

  const showImportDialog = () => {
    const automated = !bEditorIsEmpty.value

    void window.avv_upload<{ body: string, comments: { threads: string[], selection_start: number, selection_end: number, selection_paragraphs: string[], selection_text: string }[] }>({
        title: localizeText('template.docx_upload.title'),
        description: localizeText('template.docx_upload.desc'),
        path: '/documents/imports/docx',
        accept: '.docx',
        data: {
          document_id: AvvStore.state.document_id,
          automated
        }
      })
      .then(async (data) => {
        const editor = EditorFactory.main
        const node = HtmlParser.parse(data.body)
        AvvNodeGrammar.applyDocxGrammar(node)
        editor.load(node)

        // Add comments from import
        const bridge = EditorFactory.mainEntry.bridge!
        for (const comment of data.comments) {
          const thread: Backend.Models.Comment.Create = {
            lines: comment.selection_paragraphs,
            text: comment.selection_text,
            visible: true,
            private: true,
            message: comment.threads.map((text) => ({
              text,
              created_at: new Date().toUTCString(),
              updated_at: null,
              author: bridge.currentParticipant.id,
              mentions: [],
              archived_by: null,
              archived_at: null
            })),
            start: comment.selection_start,
            stop: comment.selection_end,
            assigned_participant_id: null,
            participant_id: bridge.currentParticipant.id,
            document_id: editor.negotiation.id
          }

          await bridge.createComment(thread)
        }

        if (EditorFactory.exists('draft')) {
          await editor.negotiation.asyncAcceptChanges()
          await editor.negotiation.asyncSubmitDeltaChange()

          if (automated) {
            await CompareUtils.saveVersion('docx_nego_import')
          }

          const documentStore = useDocumentStore()
          await documentStore.hydrateById(getCurrentDocumentId(), ['activity_log', 'settings_for_frontend'], true)
        }
      })
  }

  const userHasRights = computed(() => {
    return (
      AvvStore.state.is_doc_negotiable &&
      AvvStore.state.active_participant &&
      AvvStore.state.active_participant.edit === 'All' &&
      AvvStore.state.locking_party ===
        AvvStore.state.active_participant.party_type
    )
  })

  const documentNotFinished = computed(() => {
    return (
      AvvStore.state.doc_state !== 'completed' &&
      AvvStore.state.doc_state !== 'signed'
    )
  })

  return {
    showImportDialog,
    bNegotiateImportEnabled: computed(
      () =>
        userHasRights.value &&
        documentNotFinished.value &&
        (bEditorIsEmpty.value
          ? AvvStore.state.allow_docx_import
          : AvvStore.state.allow_export_with_uuids)
    ),
    bNegotiateExportEnabled: computed(
      () =>
        userHasRights.value &&
        documentNotFinished.value &&
        AvvStore.state.allow_export_with_uuids
    )
  }
}

export const sanitizeDocumentTitle = (documentName: string, throwToastOnError = true) => {
  const documentStore = useDocumentStore(getActivePinia())

  const {
    enabled: characterWhitelistEnabled,
    whitelist: characterWhitelist,
    applies_to_title: characterWhitelistAppliesToTitle
  } = documentStore.validations.character_whitelist

  const whitelist = _parseWhitelist(characterWhitelist)
  if (characterWhitelistEnabled && characterWhitelistAppliesToTitle && whitelist.length > 0) {
    const title = uniqueArray(documentName.split(''))
    const disallowedCharacters = title.filter(character => _isDisallowedCharacter(character, whitelist))

    if (disallowedCharacters.length > 0) {
      let sanitized = documentName

      disallowedCharacters.forEach(character => {
        sanitized = sanitized.replaceAll(character, '')
      })

      if(throwToastOnError) {
        useToast({
          type: 'error',
          message: localizeText('document.topbar.alerts.cannot_contain', { characters: disallowedCharacters.join(' ') }),
          inverted: true,
        })
      }

      return sanitized
    }
  }

  return documentName
}

interface ApiSaveTitleErrorResponse {
  error?: string;
  errors?: string[];
  status?: string;
}

/**
 * Saves the document title to the server and updates the store.
 *
 * @param title - The title of the document to be saved
 * @throws Will throw an error if the network request fails
 *
 * @example
 * // Ensure you sanitize the title before saving
 * const sanitizedTitle = sanitizeDocumentTitle(rawTitle);
 * await saveDocumentTitle(sanitizedTitle);
 *
 * @warning
 * This function does not sanitize the title. Always use the sanitizeDocumentTitle
 * function before calling this function to ensure the title is properly sanitized.
 */
export const saveDocumentTitle = async (title: string): Promise<void> => {
  try {
    await documentsApi.saveTitle({
      params: { id: AvvStore.state.document_id },
      data: {
        title: title
      }
    })

    AvvStore.commit('SET_DOC_NAME', title);
  } catch (error: unknown) {

    console.error('Failed to save document title:', error);

    let errorMessage = localizeText('document.topbar.alerts.save_title_failed');

    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiSaveTitleErrorResponse>;
      if (axiosError.response) {
        const { data } = axiosError.response;
        if (data.error) {
          errorMessage = data.error;
        } else if (data.errors && data.errors.length > 0) {
          errorMessage = data.errors.join(', ');
        } else if (axiosError.response.status === 422) {
          errorMessage = localizeText('document.topbar.alerts.save_title_too_long');
        }
      }
    }

    useToast({
      type: 'error',
      message: errorMessage,
      inverted: true,
    });
  }
};

const INVALID_CHARS_REGEX = /<|>/g;

export const init_doc_name_field = () => {
  const documentStore = useDocumentStore(getActivePinia())

  const documentName = ref(AvvStore.state.doc_name.replaceAll('&amp;', '&'));

  const saveDocumentTitleDebounce = useDebounce((value: string) => saveDocumentTitle(value), 1500)

  function showTitleLimitErrorToast(min: number, max: number | false, current: number): void {
    useToast({
      message: localizeText(
        max !== false
          ? 'template.err_messages.invalid_title_length'
          : 'template.err_messages.invalid_title_length_no_max',
        { min, max, current }
      ),
      type: 'error',
      inverted: true
    });
  }

  const isCharacterKey = (code: string) => { 
    const specialKeys = [ Key.Backspace, Key.Delete, Key.Enter, Key.NumpadEnter, Key.Escape, Key.AltLeft, Key.AltRight, Key.ControlLeft, Key.ControlRight, Key.ShiftLeft, Key.ShiftRight, Key.MetaLeft, Key.MetaRight, Key.CapsLock, Key.Tab, Key.Escape, Key.ArrowUp, Key.ArrowDown, Key.ArrowLeft, Key.ArrowRight, Key.Insert, Key.Delete, Key.Home, Key.End, Key.PageUp, Key.PageDown, Key.F1, Key.F2, Key.F3, Key.F4, Key.F5, Key.F6, Key.F7, Key.F8, Key.F9, Key.F10, Key.F11, Key.F12, Key.NumLock, Key.ScrollLock, Key.Pause ];
    return specialKeys.includes(code as Key);
  }

  const onUpdateDocumentName = (event: KeyboardEvent) => {
    if (isCharacterKey(event.code)) return

    const { enabled, min, max } = documentStore.validations.title_character_limit

    if (!enabled) return

    const finiteMax = max || Number.POSITIVE_INFINITY
    const newLength = documentName.value.length + 1;

    if (newLength > finiteMax) {
      showTitleLimitErrorToast(min, max, newLength);
      event.preventDefault()
    }
  }

  watch(documentName, (newValue, oldValue) => {
    if (typeof newValue === 'string' && newValue.startsWith('http')) {
      documentName.value = newValue;
      return;
    }

    if (!newValue) {
      documentName.value = oldValue;
      avv_dialog({
        alertMessage: localizeText('document.topbar.alerts.empty_title')
      });
      return;
    }

    let sanitizedValue = sanitizeDocumentTitle(newValue, true);
    if (sanitizedValue.match(INVALID_CHARS_REGEX)) {
      sanitizedValue = sanitizedValue.replace(INVALID_CHARS_REGEX, '');
    }

    if(newValue !== sanitizedValue) {
      documentName.value = sanitizedValue;
    }

    if(newValue != oldValue) {
      void saveDocumentTitleDebounce(sanitizedValue);
    }
  });

  return { documentName, onUpdateDocumentName };
};

export const init_download = () => {
  const bShowDownloadDropdown = ref(false)
  const isPendingExternalSignature = computed(
    () => AvvStore.state.doc_state === 'pending_external_signature'
  )
  const hasRelatedDocuments = computed(
    () => !!AvvStore.state.related_documents.length
  )

  const downloadAttachments = ref(false)
  watch(downloadAttachments, () => {
    bShowDownloadDropdown.value = true
  })

  const downloadBlueprint = ref(false)
  watch(downloadBlueprint, () => {
    bShowDownloadDropdown.value = true
  })

  const bDownloadInternalComments = ref(false)
  watch(bDownloadInternalComments, () => {
    bShowDownloadDropdown.value = true
  })

  const downloadSummary = ref(false)
  watch(downloadSummary, () => {
    bShowDownloadDropdown.value = true
  })

  const download = async (format: string) => {
    const data = {
      document_ids: [AvvStore.state.document_id],
      formats: [format],
      flags: [] as string[]
    }

    if (bDownloadInternalComments.value === false) {
      data.flags.push('no_private_comments')
    }

    if (downloadBlueprint.value) {
      data.formats.push('blueprint')
    }

    if (downloadAttachments.value) {
      data.formats.push('attachments')
    }

    if (downloadSummary.value) {
      data.formats.push('summary')
    }

    if (downloadAllRelated.value) {
      const relatedDocumentIds = AvvStore.state.related_documents.map(
        (doc) => doc.id
      )
      data.document_ids.push(...relatedDocumentIds)
    }

    if (format === 'document_docx_nego') {
      await CompareUtils.saveVersion('docx_nego_export')
    }

    await window.avv_download(ExportApi.create.path(), data)

    const documentStore = useDocumentStore()
    await documentStore.hydrateById(getCurrentDocumentId(), ['activity_log', 'settings_for_frontend'], true)
  }

  const handle_docx_download = (format: string) => {
    const hasPdfToDownload = Object.entries(AvvStore.state.exportableDocuments)
        .some(([id, doc]) => doc.formats.length === 1 && doc.formats[0] === 'document_pdf');

    if (downloadAllRelated.value && hasPdfToDownload) {
      useDialog(
        DownloadDialog,
        {}
      )
    } else {
      download(format)
    }
  }

  window.addEventListener('click', (e) => {
    const isDownloadElement = (elem: HTMLElement) => {
      if (!elem) return false
      if (elem.classList.contains('download')) return true
      if (elem.tagName.toLowerCase() === 'main') return false
      else return isDownloadElement(elem.parentElement)
    }
    if (!isDownloadElement(e.target)) bShowDownloadDropdown.value = false
  })

  const downloadPermissions = AvvStore.state.download
  const doc_id = AvvStore.state.document_id

  const bDownloadDisabled = !AvvStore.state.blocking_actions.download
  const bShowDownloadApprovalsTooltip = ref(false)
  const downloadAllRelated = ref(false)
  const setDownloadAllRelated = (value: boolean) =>
    (downloadAllRelated.value = value)

  return {
    bShowDownloadDropdown,
    download,
    downloadAttachments,
    downloadSummary,
    downloadPermissions,
    doc_id,
    bDownloadDisabled,
    bDownloadInternalComments,
    bShowDownloadApprovalsTooltip,
    isPendingExternalSignature,
    hasRelatedDocuments,
    downloadAllRelated,
    setDownloadAllRelated,
    downloadBlueprint,
    handle_docx_download
  }
}

export const init_create_related_doc = () => {
  const relatedDocLink = AvvStore.state.paths.relatedDoc
  const onClickRelatedDoc = () => {
    const hiddenLink = document.querySelector('.related-doc')
      .firstElementChild as HTMLElement
    hiddenLink.click()
  }

  return { onClickRelatedDoc, relatedDocLink }
}

export const init_notify = () => {
  const localize = useLocalize('document.topbar.tooltips.disabled_notifications')

  const disabledNotifications = computed(() => AvvStore.state.disabledNotifications)

  const isNotificationDisabledForParty = (party?: string) => party ? disabledNotifications.value.parties.includes(party) : true

  const disabledNotificationsDropdown = computed<V.ContextMenu.Item<unknown>[]>(() => [
    {
      type: 'checkbox',
      label: localize('disabled_for_me'),
      value: {
        get: () => disabledNotifications.value.user ?? isNotificationDisabledForParty(AvvStore.state.active_participant?.party_type),
        set: (value) => {
          disabledNotifications.value.user = value
          void persist()
        }
      }
    },
    {
      type: 'separator'
    },
    ...(
      AvvStore.state.isAuthor ? AvvStore.state.parties.map((party: string) => ({
        type: 'checkbox',
        label: localize('disabled_for_party', { party }),
        value: {
          get: () => isNotificationDisabledForParty(party),
          set: (value: boolean) => {
            if (value) {
              disabledNotifications.value.parties.push(party)
            } else {
              const index = disabledNotifications.value.parties.indexOf(party)
              disabledNotifications.value.parties.splice(index, 1)
            }
            void persist()
          }
        }
      })) : [
        {
          type: 'checkbox',
          label: localize('disabled_for_my_party'),
          value: {
            get: () => isNotificationDisabledForParty(AvvStore.state.active_participant?.party_type),
            set: (value: boolean) => {
              if(!AvvStore.state.active_participant) return
              if (value) {
                disabledNotifications.value.parties.push(AvvStore.state.active_participant?.party_type)
              } else {
                const index = disabledNotifications.value.parties.indexOf(AvvStore.state.active_participant?.party_type)
                disabledNotifications.value.parties.splice(index, 1)
              }
              void persist()
            }
          }
        }
      ]
    )
  ])

  const disabledNotificationsForSelf = computed(() => {
    const settings = disabledNotifications.value

    if (settings.user !== null) {
      return settings.user
    } else {
      return isNotificationDisabledForParty(AvvStore.state.active_participant?.party_type)
    }
  })

  const persist = async () => {
    const { disabledNotifications } = await DocumentsApi.updateNotifications({
      params: { id: AvvStore.state.document_id },
      data: {
        disabledNotifications: AvvStore.state.disabledNotifications
      }
    })

    AvvStore.state.disabledNotifications = disabledNotifications
  }

  const disabledNotificationsVisible = computed(() => {
    return !AvvStore.state.doc_status.completed && !AvvStore.state.doc_status.signed && AvvStore.state.active_participant
  })

  return { disabledNotificationsDropdown, disabledNotificationsForSelf, disabledNotificationsVisible }
}

export const init_delete = () => {
  const path = 'document.topbar.alerts'
  const canDelete = AvvStore.state.can_delete_doc
  const deleteDocLink = AvvStore.state.paths.deleteDoc
  const docDeleted = computed(() => AvvStore.state.doc_state === 'deleted')
  const action = docDeleted.value ? 'restore' : 'delete'

  const deleteDocument = () => {
    avv_dialog({
      confirmMessage: localizeText(`${path}.del_res_doc`, {
        action: localizeText(`${path}.${action}`)
      }),
      squareDisplay: true,
      warn: true,
      okButtonText: localizeText(`general.${action}`),
      confirmCallback: (value) => {
        if (value) {
          const deleteDocLink = document.querySelector('.delete-doc')
            .firstElementChild as HTMLElement
          deleteDocLink.click()
        }
      }
    })
  }

  return { canDelete, deleteDocument, deleteDocLink, docDeleted, action }
}

export const init_finish = () => {
  const canFinish = AvvStore.state.canFinishDoc

  const finishDocument = () => {
    avv_dialog({
      confirmMessage: localizeText('document.topbar.alerts.finish_res_doc', {
        action: localizeText('document.topbar.alerts.finish_document')
      }),
      squareDisplay: true,
      warn: true,
      okButtonText: localizeText('general.confirm'),
      confirmCallback: (value) => {
        if (value) {
          DocumentsApi.finish({
            data: {documents: AvvStore.state.document_id}
          }).then(() => {
            window.location.reload()
            return null;
          }).catch((error: Error) => {
            window.avv_toast({
              type: 'error',
              message: localizeText('document.topbar.alerts.finish_error', { error: error.message }),
              inverted: true
            })
          })
        }
      }
    })
  }

  return { canFinish, finishDocument }
}

export const init_mark_as_complete = (props) => {
  const path = 'document.topbar'
  const state = AvvStore.state
  const bShowMarkAsComplete =
    !state.canCurrentPartySign &&
    (!state.canCounterPartySign || AvvStore.getters.parties.length === 1) &&
    state.isInAuthorParty
  const bShowMarkAsCompleteDisabled = !state.isCurrentParticipantController
  const buttonType = state.doc_status.signed_at
    ? 'mark_in_review'
    : state.salesforce_integration
      ? 'salesforce'
      : 'mark_as_complete'

  const markAsCompleteTooltip = computed(() => {
    const markAction =
      buttonType === 'mark_as_complete'
        ? localizeText(`${path}.tooltips.mark_as_complete.actions.as_complete`)
        : localizeText(`${path}.tooltips.mark_as_complete.actions.in_review`)
    if (buttonType === 'salesforce')
      return state.isCurrentParticipantController
        ? localizeText(`${path}.tooltips.mark_as_complete.send_for_sign`)
        : localizeText(`${path}.tooltips.mark_as_complete.send_for_sign_d`)
    else {
      return state.isCurrentParticipantController
        ? localizeText(`${path}.tooltips.mark_as_complete.mark_action`, {
            action: markAction
          })
        : localizeText(`${path}.mark_as_complete.mark_action_d`, {
            action: markAction
          })
    }
  })
  const text = computed(() => {
    if (state.doc_status.signed_at)
      return localizeText(`${path}.content.mark_in_review`)
    if (state.salesforce_integration)
      return localizeText(`${path}.content.send_for_sig`)
    else return localizeText(`${path}.content.mark_as_complete`)
  })

  const onClickMarkAsComplete = (event: Event) => {
    return documentValidationDialog(
      async (valid) => {
        if (!valid) return

        await handleCharacterAutoreplacement()

        goTo(`/documents/${AvvStore.state.document_id}/mark_signatureless`, event)
      },
      true,
      true
    )
  }

  return {
    bShowMarkAsComplete,
    text,
    bShowMarkAsCompleteDisabled,
    markAsCompleteTooltip,
    onClickMarkAsComplete
  }
}

export const init_publish = () => {
  const path = 'document.topbar.tooltips.publish'
  const isController = AvvStore.state.isCurrentParticipantController
  const bShowPublish = AvvStore.getters.parties.length > 1
  const currentParty = AvvStore.state.active_participant?.party_type
  const lockedToCurrentParty = computed(
    () => AvvStore.state.locking_party === currentParty
  )
  const bPublishableParties = computed(() => {
    const possibleParties = Object.entries(AvvStore.state.assignable_roles)
      .filter((pr: Array<any>) => pr[0] !== currentParty && pr[1].length > 0)
      .map((pr: Array<any>) => pr[0])
    const existingParties = Object.values(AvvStore.state.users)
      .filter((u: any) => u.party_type !== currentParty)
      .map((u: any) => u.party_type)
    return possibleParties.length > 0 || existingParties.length > 0
  })
  const bPublishDisabled = computed(
    () =>
      !AvvStore.state.blocking_actions.send ||
      AvvStore.getters.parties.length === 1 ||
      !isController ||
      !lockedToCurrentParty.value ||
      !bPublishableParties.value
  )
  const publishedBy = AvvStore.state.doc_status.published_by

  const publishTooltip = computed(() => {
    if (publishedBy !== currentParty && AvvStore.state.doc_state !== 'locked')
      return AvvStore.state.isCurrentParticipantController
        ? localizeText(`${path}.disabled`)
        : localizeText(`${path}.disabled_no_c`)
    if (!lockedToCurrentParty.value && isController)
      return localizeText(`${path}.already_sent`)
    if (lockedToCurrentParty.value && isController)
      return localizeText(
        `${path}.${bPublishableParties.value ? 'send' : 'needs_invite'}`
      )
    if (!lockedToCurrentParty.value && !isController)
      return localizeText(`${path}.already_sent_no_c`)
    if (lockedToCurrentParty.value && !isController)
      return localizeText(`${path}.send_no_c`)
    else return 'No condition met'
  })

  const bShowPublishApprovalsTooltip = ref(false)
  const bPublishBlocked = !AvvStore.state.blocking_actions.send

  const onClickPublish = (event: Event) => {
    return documentValidationDialog(
      async (valid) => {
        if(!valid) return;

        await handleCharacterAutoreplacement()

        goTo(`/drafts/${AvvStore.state.document_id}/publish`, event)
      },
      true,
      false
    )
  }

  return {
    bShowPublish,
    bPublishDisabled,
    bShowPublishApprovalsTooltip,
    publishTooltip,
    bPublishBlocked,
    onClickPublish
  }
}

export const init_sign = () => {
  const path = 'document.topbar'
  const state = AvvStore.state
  const other =
    AvvStore.getters.parties.length > 1 ? 'counterparty' : 'signatory'
  const tick_to_complete = state.tick_to_complete
  const wasPressed = state.doc_status.signed_at
  const signRequested = state.sign_requested
  const signatureBlocked = !AvvStore.state.blocking_actions.sign
  const bShowSign = state.canCurrentParticipantSign
  const signedByCurrentParticipant =
    state.active_participant &&
    state.doc_status.signed_by_participants.includes(
      state.active_participant?.id
    )
  const twoPartyMessage = localizeText(`${path}.alerts.sign_two_party`)
  const onePartyMessage = localizeText(`${path}.alerts.sign_one_party`)
  const bSignDisabled = computed(
    () => {
      return state.body_contains_nonwhitelisted_characters ||
        !state.isCurrentParticipantController ||
      (AvvStore.state.doc_state === 'locked' &&
        (AvvStore.state.parties.length !== 1 ||
          !state.canCurrentParticipantSign) &&
        !state.can_skip_to_sign) ||
      wasPressed ||
      bHasNonAnsweredQs.value ||
      signedByCurrentParticipant ||
      (signRequested &&
        !['partially_signed', 'ready_to_sign'].includes(state.doc_state)) ||
      signatureBlocked
    }
)
  const isLockedToCurrentParty = computed(
    () => AvvStore.state.locking_party === state.active_participant?.party_type
  )
  const onClickSign = () => {
    documentValidationDialog(
      (valid) => {
        if (!valid || bSignDisabled.value) return

        const continueToSign = () => {
          const confirmTitle = tick_to_complete
            ? localizeText(`${path}.alerts.tick_to_complete.title`)
            : localizeText(`${path}.alerts.sign_confirm`)

          const confirmMessage = tick_to_complete
            ? localizeText(`${path}.alerts.tick_to_complete.message`)
            : AvvStore.getters.parties.length === 1
              ? onePartyMessage
              : twoPartyMessage

          avv_dialog({
            confirmTitle,
            confirmMessage,
            squareDisplay: true,
            confirmCallback: async (value) => {
              if (!value) return

              await handleCharacterAutoreplacement();

              window.location = state.sign_path
            }
          })
        }

        const documentStore = useDocumentStore(getActivePinia())

        const validationTypes = ['character_autoreplacement', 'character_whitelist', 'body_character_limit', 'title_character_limit'] as const
        if (validationTypes.some((v) => documentStore.validations[v].enabled !== documentStore.validations[v].setup)) {
          useSimpleDialog({
            message: localizeText('documents.notices.validations_disabled'),
            buttons: ['cancel', 'ok']
          }, {
            callback: (decision) => {
              if (decision) continueToSign()
            }
          })
        } else {
          continueToSign()
        }
      },
      true,
      true
    )
  }

  const signText = state.tick_to_complete
    ? localizeText(`${path}.content.tick_to_complete`)
    : localizeText(`${path}.content.sign`)
  const signTooltip = computed(() => {
    const tooltip_path = `${path}.tooltips.${
      state.tick_to_complete ? 'tick' : 'sign'
    }`

    if (signatureBlocked) {
      return localizeText(`${tooltip_path}.blocked`)
    } else if (signedByCurrentParticipant) {
      return localizeText(`${tooltip_path}.signed`)
    } else if (
      AvvStore.state.doc_state === 'locked' &&
      !state.canCurrentParticipantSign
    ) {
      return localizeText(`${tooltip_path}.locked`)
    } else if (bHasNonAnsweredQs.value) {
      return localizeText(`${tooltip_path}.q_err`)
    } else if (wasPressed) {
      return localizeText(`${tooltip_path}.pressed`)
    } else if (
      signRequested &&
      !['partially_signed', 'ready_to_sign'].includes(state.doc_state)
    ) {
      return `${localizeText(`${path}.tooltips.accept.pressed`, {
        other
      })} \n ${
        state.canCurrentParticipantSign
          ? localizeText(
              `${path}.tooltips.accept.proceed_after${
                state.tick_to_complete ? '_tick' : ''
              }`
            )
          : ''
      }`
    } else if (state.can_skip_to_sign) {
      return state.isCurrentParticipantController
        ? localizeText(`${tooltip_path}.go`)
        : localizeText(`${tooltip_path}.go_no_c`)
    } else if (
      state.doc_state !== 'locked' ||
      AvvStore.getters.parties.length === 1
    ) {
      return localizeText(
        `${tooltip_path}.go${
          state.isCurrentParticipantController ? '' : '_no_c'
        }`
      )
    } else {
      return localizeText(`${tooltip_path}.needs_send`, {
        controller: isLockedToCurrentParty.value
          ? !AvvStore.state.isCurrentParticipantController
            ? localizeText(`${path}.tooltips.sign.controller`)
            : ''
          : `by a ${AvvStore.state.locking_party}`
      })
    }
  })

  return { bShowSign, bSignDisabled, onClickSign, signText, signTooltip }
}

export const init_request_sign = () => {
  const path = 'document.topbar'
  const state = AvvStore.state
  const other =
    AvvStore.getters.parties.length > 1 ? 'counterparty' : 'signatory'
  const wasPressed = state.sign_requested
  const tickToComplete = state.tick_to_complete
  const signatureBlocked = !AvvStore.state.blocking_actions.sign
  const bShowRequestSign =
    (state.canCurrentPartySign && !state.canCurrentParticipantSign) ||
    (!state.canCurrentPartySign && state.canCounterPartySign)
  const bRequestSignDisabled = computed(
    () =>
      !state.isCurrentParticipantController ||
      (state.doc_state !== 'unlocked' &&
        AvvStore.getters.parties.length !== 1) ||
      wasPressed ||
      AvvStore.state.doc_state === 'ready_to_sign' ||
      bHasNonAnsweredQs.value ||
      signatureBlocked
  )
  const requestSignText = state.canCurrentPartySign
    ? localizeText(
        `${path}.content.request_${tickToComplete ? 'tick' : 'sign'}`
      )
    : localizeText(`${path}.content.accept`)
  const nonSealedParties = computed(() =>
    AvvStore.getters.parties
      .filter((p) => !AvvStore.state.sealed_parties.includes(p))
      .join(' and ')
  )

  const requestPath = `${path}.tooltips.request_${
    tickToComplete ? 'tick' : 'sign'
  }`
  const tooltipPath = `${path}.tooltips.${tickToComplete ? 'tick' : 'sign'}`

  const acceptTooltip = computed(() => {
    if (state.canCurrentPartySign) {
      if (signatureBlocked) {
        return localizeText(`${tooltipPath}.blocked`)
      } else if (bHasNonAnsweredQs.value) {
        return localizeText(`${tooltipPath}.q_err`)
      } else if (wasPressed || AvvStore.state.doc_state === 'ready_to_sign') {
        if (AvvStore.state.doc_state === 'ready_to_sign') {
          return localizeText(`${requestPath}.requested`)
        } else if (AvvStore.getters.parties.length === 1) {
          return localizeText(`${requestPath}.request_pending_one`, {
            party: AvvStore.state.active_participant?.party_type
          })
        } else {
          return localizeText(`${requestPath}.request_pending`, {
            parties: nonSealedParties.value
          })
        }
      } else if (
        state.doc_state === 'unlocked' ||
        AvvStore.getters.parties.length === 1
      ) {
        return localizeText(
          `${requestPath}.go${
            state.isCurrentParticipantController ? '' : '_no_c'
          }`
        )
      } else {
        return localizeText(`${requestPath}.publish_err`)
      }
    } else {
      if (bHasNonAnsweredQs.value) {
        return localizeText(`${path}.tooltips.accept.q_err`)
      } else if (wasPressed) {
        return `${localizeText(`${path}.tooltips.accept.pressed`, {
          other
        })} \n ${
          state.canCurrentParticipantSign
            ? localizeText(
                `${path}.tooltips.accept.proceed_after${
                  tickToComplete ? '_tick' : ''
                }`
              )
            : ''
        }`
      } else if (
        state.doc_state === 'unlocked' &&
        AvvStore.getters.parties.length === 1
      ) {
        if (state.isCurrentParticipantController) {
          return 'Click this button to indicate aceptance of this draft of the document'
        } else {
          return 'A controller can press this button to indicate acceptance of this draft of the document'
        }
      } else if (wasPressed) {
        return localizeText(`${path}.tooltips.accept.publish_err`)
      } else {
        return localizeText(`${requestPath}.publish_err`)
      }
    }
  })

  const confirmKey = computed(() => {
    if (state.canCurrentParticipantSign) {
      if (tickToComplete) {
        return 'request_tick_confirm'
      } else {
        return 'request_sign_confirm'
      }
    } else if (state.canCurrentPartySign) {
      if (tickToComplete) {
        return 'request_party_tick_confirm'
      } else {
        return 'request_sign_confirm'
      }
    } else {
      return 'accept_confirm'
    }
  })

  const onClickRequestSign = () => {
    if (bRequestSignDisabled.value) return

    documentValidationDialog(
      (valid) => {
        if (!valid) return

        avv_dialog({
          confirmTitle: localizeText(`${path}.alerts.${confirmKey.value}.title`),
          confirmMessage: localizeText(
            `${path}.alerts.${confirmKey.value}.message`
          ),
          squareDisplay: true,
          confirmCallback: async (value) => {
            if (!value) return

            await handleCharacterAutoreplacement()

            window.location.href = `/drafts/${AvvStore.state.document_id}/sign_request`
          }
        })
      },
      true,
      true,
    )
  }

  return {
    bShowRequestSign,
    bRequestSignDisabled,
    onClickRequestSign,
    requestSignText,
    acceptTooltip
  }
}

export const init_download_debug = () => {
  const canDownloadDebug = computed(() => AvvStore.state.can_download_debug)
  const debugDownloadText = 'Download dump'
  const debugDownloadParams = computed(() => {
    const params = new URLSearchParams()
    params.set('doc_id', AvvStore.state.document_id)
    params.set('temp_id', AvvStore.state.template_id)
    return params.toString()
  })

  return { canDownloadDebug, debugDownloadText, debugDownloadParams }
}

export const init_integrations = () => {
  const hasIntegrations = computed(() => AvvStore.state.hasIntegrations)

  return { hasIntegrations }
}

export const init_create_summary_dialog = () => {
  const showSummayButton = computed(() => AvvStore.state.summaryOptions.showSummaryButton)
  const summaryTrigger = computed(() => AvvStore.state.summaryOptions.summaryTrigger)
  const docState = computed(() => AvvStore.state.doc_state)

  const openCreateSummaryDialog = async (_manualCreation = true) => {
    const {tagged_clauses_with_deviations} = await SummaryDocumentsApi.taggedClausesWithDeviations<{tagged_clauses_with_deviations: {id: string, name: string}[]}>({query:{document_id: AvvStore.state.document_id}})
    if (!tagged_clauses_with_deviations) return
    return useDialog(createSummaryDialog, {clauses: tagged_clauses_with_deviations, manualCreation: _manualCreation})
  }

  if(docState.value === summaryTrigger.value) {
    openCreateSummaryDialog(false).catch(e => {
      useErrorToast(e)
    })
  }

  return { openCreateSummaryDialog, showSummayButton }
}
